.login-wrap{
    padding: 100px 0px;
    position: relative;
    font-size: 14px;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .l-w-box{
        max-width: 496px;
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        padding: 50px 70px;
        // z-index: 9;
        // position: relative;
        border: $grey-border;

        .dashed-border:before{
            content: "";
            height: 2px;
            border: dashed 1px #e6e6ea;
            display: block;
        }

        @include media-breakpoint-down(sm){
            max-width: 90%;
            padding: 50px 20px;
        }
    }

    @include media-breakpoint-down(sm){
        padding: 20px 0px;
    }
}






