$opensans: Open Sans;

//COLORS VARIABLES
$primary: #1e1e1e ;

$secondary: #f9f9fe;

$text-light: #959595;

$text-grey: #6f6f6f;

$red: #f32e48;

$green: #28a745;


//BORDER VARIABLES
$grey-border: 1px solid #f1efef;

$grey-border-top: 1px solid #f1efef;

$black-border-top: 1px solid $primary;

$grey-border-bottom: 1px solid #f1efef;

$grey-border-left: 1px solid #f1efef;

$dash-border: 1px dashed #d5d5d8 !important;

