//FONT FACE
@font-face {
    font-family: OpenSans-Regular;
    src: url('../fonts/OpenSans-Regular.ttf') format('TTF');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.mt-24{
    margin-top: 24px;
}

.mb-24{
    margin-bottom: 24px;
}

.mt-30{
    margin-top: 30px;
}

.mb-30{
    margin-bottom: 30px;
}

.my-30{
    margin-top: 30px;
    margin-bottom: 30px;
}

.pt-50{
    padding-top: 50px;

    @include media-breakpoint-down(lg){
        padding-top: 30px;
    }
}

.pb-50{
    padding-bottom: 50px;

    @include media-breakpoint-down(lg){
        padding-bottom: 30px;
    }
}

.py-50{
    padding-bottom: 50px;
    padding-top: 50px;

    @include media-breakpoint-down(lg){
        padding-bottom: 30px;
        padding-top: 30px;
    }
}

.pt-100{
    padding-top: 100px;

    @include media-breakpoint-down(lg){
        padding-top: 70px;
    }

    @include media-breakpoint-down(lg){
        padding-top: 50px;
    }
}

.pb-100{
    padding-bottom: 100px;

    @include media-breakpoint-down(lg){
        padding-bottom: 70px;
    }
}

.py-100{
    padding-top: 100px;
    padding-bottom: 100px;

    @include media-breakpoint-down(lg){
        padding-bottom: 70px;
        padding-top: 70px;
    }

    @include media-breakpoint-down(lg){
        padding-bottom: 50px;
        padding-top: 50px;
    }
}
.h-30{
    height: 30px !important;
    line-height: 1.3;

    @include media-breakpoint-down(sm){
        height: auto !important;
    }
}
.dash-border{
    border: $dash-border;
}

/*********Extra Css**********/

.secondary-btn {
    border: 1px solid #f9f9fe;
}
.deal-detail-img {
    height: 315px;
    overflow: hidden;

    img{
        height: 315px;
        object-fit: cover;
    }
}

#deal_detail_slider .owl-dots{
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.red-btn{
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    font-weight: 600;
    height: 46px;

    &:hover{
        color: white;
    }

    &:after{
        transform: translate3D(-100%,100%,0);
    }

    &:before{
        background: #ec0e20 ;
        z-index: -2;
        transform: translate3D(0,0,0);
    }
}

.red-btn:before, .red-btn:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #ec4351;
    z-index: -1;
    // transform: translate3D(0,-100%,0);
    transition: all .5s;
}

.red-btn:before {
    background: #ec0e20;
    z-index: -2;
    transform: translate3D(0, 0, 0);
}

.red-btn:after {
    transform: translate3D(-100%, 100%, 0);
}

.deal-wrap{
    border: 1px solid #f1efef;
    background-color: #fff;
    transition: all ease 0.3s;

    &:hover{
        box-shadow: 0 0 28px 0 rgb(0 0 0 / 10%);
        color: #1e1e1e !important;
        transition: all ease 0.3s;

        img{
            transform: scale(1.1, 1.1);
            transition: all 0.2s linear;
        }
    }

    .deal-img{
        height: 200px;
        overflow: hidden;

        img{
            transition: all 0.2s linear;
            width: 100%;
        }
    }
}

.primary-btn{
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    font-weight: 600;
    height: 46px;

    &:hover{
        color: white !important;
    }

    &:after{
        transform: translate3D(-100%,100%,0);
    }

    &:before{
        background: #1e1e1e ;
        z-index: -2;
        transform: translate3D(0,0,0);
    }
}

.primary-btn:before, .primary-btn:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #393a39;
    transition: all .5s;
    z-index: -1;
}

.delete-btn .trash-item:hover {
    color: #f32e48;
}

.font-weight-bold{
    font-weight: 700;
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

