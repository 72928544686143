button:focus{
    box-shadow: none !important;
}

//Default Button
.btn{
  outline: none;
  background: transparent;
  border: none;
//   padding: 8px 10px;

  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 0;
}

.btn:hover:after{
  transform: translate3D(0,0,0);
  transition: all .5s;
}

//Primary Button
.primary-btn{
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    font-weight: 600;
    height: 46px;


    &:before, &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #393a39;
        z-index: -1;
        transform: translate3D(0, -100%, 0);
        transition: all 0.5s;
    }

    &:hover{
        color: $white;
    }

    &:after{
        transform: translate3D(-100%,100%,0);
    }
}

.primary-btn:before{
    background: #1e1e1e ;
    z-index: -2;
    transform: translate3D(0,0,0);
}

//Secondary Button
.secondary-btn{
    color: $primary;
    text-transform: capitalize;
    font-weight: 600;
    height: 46px;

    &:before, &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #f1efef;
        z-index: -1;
        transform: translate3D(0,-100%,0);
        transition: all .5s;
    }

    &:hover{
        color: $primary;
    }

    &:after{
        transform: translate3D(-100%,100%,0);
    }
}

.secondary-btn:before{
    background: white ;
    z-index: -2;
    transform: translate3D(0,0,0);
}

//Outline Button
.outline-btn{
    color: $primary;
    text-transform: capitalize;
    font-weight: 600;
    // height: 46px;
    border: solid 1px #f1efef;

    &:before, &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #f1efef;
        z-index: -1;
        transform: translate3D(0,-100%,0);
        transition: all .5s;
    }

    // &:hover{
    //     color: $white;
    // }

    &:after{
        transform: translate3D(-100%,100%,0);
    }
}

.outline-btn:before{
    background: $white ;
    z-index: -2;
    transform: translate3D(0,0,0);
}

//Dash Button
.dash-btn{
    color: $primary;
    text-transform: capitalize;
    font-weight: 600;
    border: dashed 1px $primary;

    &:before, &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: $primary;
        z-index: -1;
        transform: translate3D(0,-100%,0);
        transition: all .5s;
        // color: white;
    }

    &:hover{
        color: $white;
    }

    &:after{
        transform: translate3D(-100%,100%,0);
    }
}

.outline-btn:before{
    background: $white ;
    z-index: -2;
    transform: translate3D(0,0,0);
}












