header{
    box-shadow: 0 0 9.7px 0.3px rgba(232, 228, 228, 0.68);
    border: solid 1px #f1efef;

    .navbar-brand{
        width: 135px;
    }

    .navbar-brand:after{
        content: "";
        width: 1px;
        background-color: #f1efef;
        height: 28px;
        position: absolute;
        right: -17px;

        @include media-breakpoint-down(lg){
            content: none;
        }
    }

    .navbar-collapse{
        padding-left: 6px;

        @include media-breakpoint-down(lg){
            padding: 0px;
        }

        .navbar-nav{
            li:before{
                content: none;
            }
            .nav-link{
                font-size: 15px;
                color: $primary;
                margin: 0px 11px;
                padding: 0px;
                position: relative;

                &:hover{
                    color: black !important;

                    @include media-breakpoint-down(lg){
                        color: #ffc107 !important;
                    }
                }

                &:after{
                    background: none repeat scroll 0 0 transparent;
                    bottom: 0;
                    content: "";
                    display: block;
                    height: 1px;
                    position: absolute;
                    background: $primary;
                    transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    width: 0;
                }

                &:hover:after{
                    width: 100%;
                    left: 0;
                }
            }
        }
    }

    .header-right-wrap{
        width: 18%;

        @include media-breakpoint-down(lg){
            width: auto;
        }

        .header-right{
            a{
                font-size: 15px;
                font-weight: 600;
                color: $primary;
                display: flex;
                align-items: baseline;
            }
        }

        @include media-breakpoint-down(lg){
            svg{
              width: 12px;
            }
        }

        .cart-badge {
            border-radius: 50%;
            background-color: #d40303;
            color: #fff;
            line-height: 15px;
            width: 15px;
            height: 15px;
            font-size: 9px;
            position: absolute;
            top: -1px;
            /* top: 21px; */
            /* right: 63px; */
            left: -9px;
            text-align: center;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
        }

        .username {

            ul {
                box-shadow: 0px 0px 13px 2px rgba(94, 92, 92, 0.09), 0px 1px 10px 1px rgba(74, 72, 72, 0.11);
                border: 0px;

                li {

                    &:before{
                        content: none;
                    }
                    &:hover{
                        background-color: #1e1e1e;
                        color: white !important;

                        a {
                            background-color: #1e1e1e;
                            color: white !important;
                        }
                    }
                }

                .dropdown-item.active {
                    background-color: black;
                    color: white;
                }
            }

            .dropdown-menu {
                position: absolute;
                z-index: 3;
                opacity: 0;
                visibility: hidden;
                text-align: left;
                margin: 0;
                background-color: #fff;
                display: block;
                border-radius: 0;
                transform: translateY(20px);
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -webkit-transform: translateY(20px);
                -moz-transform: translateY(20px);
                -ms-transform: translateY(20px);
                -o-transform: translateY(20px);
                /*width: 100%;*/
                min-width: auto;
                border: 0;
                padding: 0;
                box-shadow: 0 0 4px #ececec;
            }

            &:hover{
                .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0px);
                    -webkit-transform: translateY(0px);
                    -moz-transform: translateY(0px);
                    -ms-transform: translateY(0px);
                    -o-transform: translateY(0px);
                    z-index: 99;
                }
            }

            .dropdown-toggle{
                &:hover, &:focus, &:active{
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg){

    .navbar-collapse {
        position: fixed;
        background-color: $primary;
        top: 0px;
        left: 0px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        width: 230px;
        transition: all 0.4s ease;
        display: block;
        z-index: 3;
        height: 100%;
        transform: translate3d(-311px, 0, 0);
        box-shadow: 0 0 28px 0 rgba(0, 0, 0, 10%);
    }
    .navbar-collapse.collapsing {
        height: 100% !important;
        transition: all 0.2s ease;
        left: 0px;
        transform: translate3d(0, 0, 0);
    }
    .navbar-collapse.show {
        left: 0;
        transform: translate3d(0px, 0, 0);
        transition: all 0.4s ease;
    }

    // header .collapse:not(.show) {
    //     display: block !important;
    // }

}

#nav-icon2 {
    margin-top: 5px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    padding: 0px;
    padding-right: 20px;
}

#nav-icon2 span {
    display: block;
    position: absolute;
    height: 1px;
    width: 10px;
    background: #1e1e1e;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 4px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 8px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
    left: 5px;
    top: 4px;
}

#nav-icon2.open span:nth-child(2) {
    left: calc(50% - 1px);
    top: 4px;
}

#nav-icon2.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
    left: 5px;
    top: 8px;
}

#nav-icon2.open span:nth-child(6) {
    left: calc(50% - 1px);
    top: 8px;
}

.header-search-location{
    height: 35px;

    .b-b-search{
        input{
            height: 20px;
            padding-left: 0px;
        }

    }

    .b-b-location{
        // .select2-container{
        //     width: 100% !important;
        // }
        // .select2-selection--single{
        //     border: 0;
        // }
        // .select2-selection__rendered{
        //     font-size: 12px;
        //     color: $text-light !important;
        // }
        ul li{
            &:before{
                content: none;
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .mbl-menu{
        img{
            width: 150px;
        }
        li{
            border-bottom: 1px solid #353535;
        }
        .nav-link{
            color: white !important;
            margin: 0px !important;
        }
    }
    .navbar-toggler.open {
        margin-top: 3px !important;

        span{
            width: 6px !important;
        }
    }
}
